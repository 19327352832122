import './App.css';
import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import productsContext from './Contexts/ProductsContext';
import Toast from './Toast/Toast';
import Basket from './Components/Basket';
import productsData from './Data/ProductsData';
import ScrollTopButton from './Components/ScrollTopButton';
import Loading from './Components/Loading';

export default function App() {
  const [allProducts, setAllProducts] = useState(productsData);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [sliderScrollPosition, setSliderScrollPosition] = useState({scrollTop: 0, scrollLeft: 0});
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isShowToast, setIsShowToast] = useState(false);
  const [isShowBasket, setIsShowBasket] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginated, setPaginated] = useState([]);
  const [userCart, setUserCart] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);
  const [dropDown, setDropDown] = useState(false);
  const [totalPrice, setTotalPrice] = useState([0, 0]);
  const [toggleEye, setToggleEye] = useState('');
  const [articles, setArticles] = useState([]);
  const [articlesCurrentPage, setArticlesCurrentPage] = useState(1);
  const [articlesPagination, setArticlesPagination] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  let router = useRoutes(routes);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <>
      {
        loading ? (<Loading />) : (
          <productsContext.Provider
          value={{allProducts, setAllProducts, isShowMenu, setIsShowMenu, isShowToast, setIsShowToast,
          isShowBasket, setIsShowBasket, currentPage, setCurrentPage, paginated, setPaginated,
          userCart, setUserCart, scrollPosition, setScrollPosition, sliderScrollPosition,
          setSliderScrollPosition, filterProduct, setFilterProduct, dropDown, setDropDown,
          totalPrice, setTotalPrice, toggleEye, setToggleEye, articles, setArticles,
          articlesCurrentPage, setArticlesCurrentPage, articlesPagination, setArticlesPagination,
          isCheck, setIsCheck}}>
          <ScrollTopButton />
          <Basket />
          <Toast />
          {router}
        </productsContext.Provider>
        )
      }
    </>
  )
}
