import React, { useState, useEffect } from 'react';

export default function Timer({ date_and_time }) {
    const [time, setTime] = useState(date_and_time);
    useEffect(() => {
        setTimeout(() => {
            setTime(time - 1000);
        }, 1000);
    }, [time]);
    const dateAndTimeHandler = (milliseconds) => {
        let totalSeconds = parseInt(Math.floor(milliseconds / 1000));
        let totalMinutes = parseInt(Math.floor(totalSeconds / 60));
        let totalHours = parseInt(Math.floor(totalMinutes / 60));
        let totalDays = parseInt(Math.floor(totalHours / 24));
        let seconds = parseInt(totalSeconds % 60);
        let minutes = parseInt(totalMinutes % 60);
        let hours = parseInt(totalHours % 60);
        if (seconds < 10) {
            seconds = "0" + seconds;
        };
        if (minutes < 10) {
            minutes = "0" + minutes;
        };
        if (hours < 10) {
            hours = "0" + hours;
        };
        if (totalDays < 10) {
            totalDays = "0" + totalDays;
        };
        return `${seconds} : ${minutes} : ${hours} : ${totalDays}`;
    };
  return (
    <>
      {dateAndTimeHandler(time)}
    </>
  )
}
