import React, { useContext, useEffect } from 'react';
import { LuArrowUpFromDot } from "react-icons/lu";
import productsContext from '../Contexts/ProductsContext';

export default function ScrollTopButton() {
    const contextData = useContext(productsContext);
    const handlerScroll = () => {
        const positionY = window.pageYOffset;
        contextData.setScrollPosition(positionY);
    };
    useEffect(() => {
    window.addEventListener('scroll', handlerScroll);
    return () => {
        window.removeEventListener('scroll', handlerScroll);
    };
    }, []);
    const goToTopPage = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
    };
  return (
    <button className={`scroll-top-btn ${contextData.scrollPosition >= 200 ? 'show' : 'hide'}`} onClick={goToTopPage}>
        <LuArrowUpFromDot />
    </button>
  )
}
