import Home from './Components/Home';
import Store from './Components/Store';
import About from './Components/About';
import Contact from './Components/Contact';
import Product from './Components/Product';
import NotFound from './Components/NotFound';
import Account from './Components/Account';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import Panel from './Components/Panel';
import Articles from './Components/Articles';
import Post from './Components/Post';

let routes = [
    {path: '/', element: <Home />},
    {path: '/store', element: <Store />},
    {path: '/products/:productID', element: <Product />},
    {path: '/articles', element: <Articles />},
    {path: '/article/:articleID', element: <Post />},
    {path: '/about', element: <About />},
    {path: '/contact', element: <Contact />},
    {
        path: '/account', element: <Account />, children: [
            {path: 'log-in', element: <Login />},
            {path: 'sign-up', element: <SignUp />}
        ]
    },
    {path: '/dashboard', element: <Panel />},
    {path: '*', element: <NotFound />}
];

export default routes;
