import React, { useContext, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import productsContext from '../Contexts/ProductsContext';
import axios from 'axios';
import Article from './Article';

export default function Articles() {
  const contextData = useContext(productsContext);
  useEffect(() => {
    axios.get('https://amiraghajan78.github.io/techno-store-articles/articles.json')
      .then((response) => {
        contextData.setArticles(response.data);
        console.log("data : ", response.data);
        console.log("articles : ", contextData.articles);
      })
  }, []);

  let itemCount = 4;
  let pagesNumbers;
  useEffect(() => {
    let endIndex = itemCount * contextData.articlesCurrentPage;
    let startIndex = endIndex - itemCount;
    let allShownProducts = contextData.articles.slice(startIndex, endIndex);
    contextData.setArticlesPagination(allShownProducts);
  }, [contextData.articlesCurrentPage]);
  const changePaginate = (newPage) => {
    contextData.setArticlesCurrentPage(newPage);
  };
  const pagesCount = Math.ceil(contextData.articles.length / itemCount);
  pagesNumbers = Array.from(Array(pagesCount).keys());
  return (
    <div>
        <Header />
        <section className='articles-container'>
          {
            contextData.articlesPagination.map(post => (
              <Article key={post.id} {...post} />
            ))
          }
        </section>
        <section className='articles-pagination-container flex-center'>
          <ul className='article-patination-menu'>
            {
              pagesNumbers.map((pageNumber) => (
                <li key={pageNumber + 1} onClick={() => changePaginate(pageNumber + 1)} 
                className={`article-page ${pageNumber + 1 === contextData.articlesCurrentPage ? 'pagination-active' : ''}`}>{pageNumber + 1}</li>
              ))
            }
          </ul>
        </section>
        <Footer />
    </div>
  )
}
