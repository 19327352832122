import React, { useState , useEffect } from 'react';
import Footer from './Footer';
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { FaHeart } from "react-icons/fa6";
import { RiShareForwardLine } from "react-icons/ri";
import { FaRegComment } from "react-icons/fa";
import posts from '../Data/Comments';
import { TfiComments } from "react-icons/tfi";
import Header from './Header';

export default function About() {
  const [allPosts, setAllPosts] = useState(posts);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginated, setPaginated] = useState([]);
  let postsCount = 2;
  let pagesNumbers;
  useEffect(() => {
    let endIndex = postsCount * currentPage;
    let startIndex = endIndex - postsCount;
    let allPostsShown = allPosts.slice(startIndex, endIndex);
    setPaginated(allPostsShown);
  }, [currentPage]);
  const changePaginate = (newPage) => {
    setCurrentPage(newPage);
  };
  const pagesCount = Math.ceil(allPosts.length / postsCount);
  pagesNumbers = Array.from(Array(pagesCount).keys());
  useEffect(() => {
    let sliderNumber = 1;
    const timeOut = setInterval(() => {
      sliderNumber += 1;
      setCurrentPage(sliderNumber);
      if (sliderNumber > pagesCount) {
        sliderNumber = 1;
        setCurrentPage(sliderNumber);
      };
    }, 3000);
  }, []);
  return (
    <div>
      <Header />
      <section className='about-content-container'>
        <div className='profile-container'>
          <div className='instagram-border position-center'>
            <div className='white-bg position-center'>
              <div className='profile-image-container position-center'>
                <img className='profile-image' alt='' src='../images/profile-image.png' />
                <div className='profile-image-cover position-center'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='about-description-container'>
          <div className='description-header flex-center'>
            <img alt='' src='../images/react-label.png' className='react-label' />
            <h4>فروشگاه اینترنتی تکنو استور</h4>
            <img alt='' src='../images/react-label.png' className='react-label' />
          </div>
          <p>
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت 
          چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،
           و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای
           زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت
           بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت
           می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز 
          شامل حروفچینی دستاوردهای اصلی، و جوابگوید.
          </p>
        </div>
        <div className='customer-reviews flex-center'>
          <i><TfiComments /></i>
          <h4>نظرات مشتریان</h4>
        </div>
        <div className='users-posts-slider flex-center'>
          {
            paginated.map(post => (
              <article className='user-comment' key={post.id}>
                <section className='avatar-container'>
                  <img alt='user-avatar' src={post.avatar} className='avatar-img position-center' />
                </section>
                <section className='posts-infos-container'>
                  <span className='post-name'>{post.name}</span>
                  <p className='post-description'>
                  {post.comment}
                  </p>
                  <span className='post-date'>{post.date}</span>
                  <div className='post-icons-container flex-center'>
                    <div><span>78</span><i><FaRegComment /></i></div>
                    <div><span>56</span><i><RiShareForwardLine /></i></div>
                    <div><span>48</span><i><FaHeart /></i></div>
                    <div><span>60</span><i><AiOutlineDislike /></i></div>
                    <div><span>182</span><i><AiOutlineLike /></i></div>
                  </div>
                </section>
              </article>
            ))
          }
          <div className='dot-container flex-center'>
            {
              pagesNumbers.map(pageNumber => (
                <span className={`dot ${pageNumber + 1 === currentPage ? 'dot-active' : ''}`} key={pageNumber + 1} onClick={() => changePaginate(pageNumber + 1)}></span>
              ))
            }
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
