import React from 'react';

export default function Loading() {
  return (
    <div className='loading-container flex-center'>
        <div className="wrapper">
            <span></span>
        </div>
        <h3>در حال بارگذاری...</h3>
    </div>
  )
}
