import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { NavLink, Outlet } from 'react-router-dom';
import { FiUserPlus } from "react-icons/fi";
import { LuLogIn } from "react-icons/lu";

export default function Account() {
  return (
    <div>
        <Header />
        <section className='message-container flex-center'>
            <h6>
                کاربر گرامی! قبل از خرید از فروشگاه تکنو استور ابتدا در سایت ثبت نام کنید. یا اگر از قبل ثبت نام کرده اید وارد حساب کاربری خود شوید. با تشکر...
                <img alt='' src='../images/emoji-3.png' style={{width: '30px', height: '30px', marginRight: '15px'}} />
            </h6>
        </section>
        <section className='router-links-container flex-center'>
            <NavLink to='sign-up'>
                <button className='account-btn'>
                    ثبت نام در تکنو استور
                    <i><FiUserPlus /></i>
                </button>
            </NavLink>
            <NavLink to='log-in'>
                <button className='account-btn'>
                    ورود به پنل کاربری
                    <i><LuLogIn /></i>
                </button>
            </NavLink>
        </section>
        <Outlet />
        <Footer />
    </div>
  )
}
