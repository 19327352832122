import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import productsContext from '../Contexts/ProductsContext';
import Header from './Header';
import Footer from './Footer';
import { FaBookOpenReader } from "react-icons/fa6";

export default function Post() {
    const contextData = useContext(productsContext);
    let params = useParams();
    let mainArticleData = contextData.articles.find(post => post.id == params.articleID);
  return (
    <>
      <Header />
      <section className='main-article-container'>
        <div className='main-article'>
          <div className='main-article-image-container flex-center'>
            <img alt='' src={mainArticleData.image} className='main-article-image' />
          </div>
          <div className='main-article-info-container flex-center'>
            <h3><i><FaBookOpenReader className='article-icon' /></i>{mainArticleData.post_title}<i><FaBookOpenReader className='article-icon' /></i></h3>
            <p>{mainArticleData.description}</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
