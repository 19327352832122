import React, { useContext, useRef } from 'react';
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { IoIosClose } from "react-icons/io";
import productsContext from '../Contexts/ProductsContext';
import { useNavigate } from 'react-router-dom';

export default function SignUp() {
  const passRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/;
  const passRef = useRef();
  const checkRef = useRef();
  const nameRef = useRef();
  const checkErrRef = useRef();
  const nameErrRef = useRef();
  const passErrRef = useRef();
  const contextData = useContext(productsContext);
  const navigate = useNavigate();
  const togglePasswordInput = (e) => {
    e.preventDefault();
    if (passRef.current.attributes.type.value === 'password') {
      passRef.current.attributes.type.value = 'text';
      contextData.setToggleEye('text');
    } else {
      passRef.current.attributes.type.value = 'password';
      contextData.setToggleEye('password');
    };
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let checkBoxChecked = checkRef.current.checked;
    let userName = nameRef.current.value;
    let password = passRef.current.value;
    let mainPass = passRegex.test(password);
    if (checkBoxChecked && userName.length >= 4 && mainPass) {
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    } else {
      checkErrRef.current.innerText = 'لطفا تیک گزینه قوانین را بزنید.';
      if (userName.length < 4) {
        nameErrRef.current.innerText = 'نام کاربری حداقل باید 4 کاراکتر باشد.';
      };
      if (userName === '') {
        nameErrRef.current.innerText = 'لطفا نام کاربری را وارد کنید.';
      }
      if (password === '') {
        passErrRef.current.innerText = 'لطفا گذرواژه را وارد کنید.';
      }
      if (password.length < 8 && password.length > 16) {
        passErrRef.current.innerText = 'گذرواژه باید حداقل 8 و حداکثر 16 کاراکتر باشد.';
      }
      if (!mainPass) {
        passErrRef.current.innerText = 'گذرواژه باید ترکیبی از حداقل 1 حرف بزرگ 1 حرف کوچک 1 عدد و یک کاراکتر ویژه باشد.';
      }
    };
  };
  return (
    <section className='sign-up-container flex-center'>
      <form action='#' className='account-form'>
        <div className='form-row-1 flex-center'>
          <label htmlFor='user-name-input'>نام کاربری : </label>
          <div className='div-elem'>
            <input type='text' className='account-form-input' ref={nameRef} />
            <i className='placeholder-icon'><FaUser /></i>
          </div>
          <i className='message-icon flex-center'><TiTick style={{display: 'none'}} /> <IoIosClose style={{display: 'none'}} /></i>
          <span className='form-message-label' ref={nameErrRef}></span>
        </div>
        <div className='form-row-2 flex-center'>
          <label htmlFor='password-input'>گذرواژه : </label>
          <div className='div-elem'>
            <input type='password' className='account-form-input' ref={passRef} />
            <i className='placeholder-icon'><FaLock /></i>
            <button className='eye-btn' onClick={(e) => togglePasswordInput(e)}>
              <i>
                {
                  contextData.toggleEye === 'text' ? <IoEyeOff /> : <IoEye />
                }
              </i>
            </button>
          </div>
          <i className='message-icon flex-center'><TiTick style={{display: 'none'}} /> <IoIosClose style={{display: 'none'}} /></i>
          <span className='form-message-label' ref={passErrRef}></span>
        </div>
        <div className='form-row-3 flex-center'>
          <label htmlFor='email-input'>آدرس ایمیل : </label>
          <div className='div-elem'>
            <input type='text' className='account-form-input' />
            <i className='placeholder-icon'><MdEmail /></i>
          </div>
          <i className='message-icon flex-center'><TiTick style={{display: 'none'}} /> <IoIosClose style={{display: 'none'}} /></i>
          <span className='form-message-label'></span>
        </div>
        <div className='form-row-4 flex-center'>
          <div style={{width: '100%'}}>
            <input type='checkbox' id='privacy-checkbox' ref={checkRef} />
            <label htmlFor='privacy-checkbox' className='privacy-label'>شرایط و قوانین را قبول دارم</label>
          </div>
          <i className='message-icon flex-center'><TiTick style={{display: 'none'}} /> <IoIosClose style={{display: 'none'}} /></i>
          <span className='form-message-label' ref={checkErrRef}></span>
        </div>
        <div className='form-row-5 flex-center'>
          <button className='account-form-btn' onClick={(e) => submitHandler(e)}>ثبت نام</button>
        </div>
      </form>
    </section>
  )
}
