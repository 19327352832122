import React, { useContext } from 'react';
import { SlBasket } from "react-icons/sl";
import { FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import productsContext from '../Contexts/ProductsContext';

export default function Products(props) {
  const contextData = useContext(productsContext);
  const addToCart = (props) => {
    contextData.setIsShowToast(true);
    setTimeout(() => {
      contextData.setIsShowToast(false);
    }, 5000);
    let isInUserCart = contextData.userCart.some(basketProduct => basketProduct.name === props.name);
    if (!isInUserCart) {
      let newProduct = {
        id: contextData.userCart.length + 1,
        name: props.name,
        price: props.price,
        image: props.image,
        count: 1
      };
      contextData.setUserCart(prevProduct => [...prevProduct, newProduct]);
    } else {
      let userCart = [...contextData.userCart];
      let newUserCart = userCart.map((basketProduct) => {
        if (basketProduct.name === props.name) {
          basketProduct.count += 1;
        };
        return basketProduct;
      });
      contextData.setUserCart(newUserCart);
    };
    contextData.setTotalPrice(prevPrice => [...prevPrice, props.price]);
  };
  return (
    <div className='main-product-cart' key={props.id}>
      <img alt='' src={props.image} className='main-product-image' />
      <span className='main-product-name'>{props.name}</span>
      <span className='main-product-price'>تومان {props.price}</span>
      <div className='product-cart-btn-container flex-center'>
      <Link to={`/products/${props.id}`}>
          <button className='info-btn'>
              <i><FaPlus /></i>
              <label className='product-tooltip'>
              جزئیات محصول
              <span className='tooltip-triangle'></span>
              </label>
          </button>
      </Link>
      <button className='add-to-cart-btn' onClick={() => addToCart(props)}>
          افزودن به سبد
          <i><SlBasket /></i>
      </button>
      </div>
    </div>
  )
}
