import React from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { FaHeart } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { FaRegCommentDots } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";

export default function Article(props) {
  return (
    <article className='techno-store-article'>
      <section className='article-image-container flex-center'>
        <img alt='' src={props.image} className='article-image' />
      </section>
      <section className='article-body'>
        <Link to={`/article/${props.id}`} className='article-title'>{props.title}</Link>
        <p className='article-abstract'>
          {props.abstract}
        </p>
        <button className='article-read-more-btn'>
          بیشتر بخوانید...
          <i><MdKeyboardDoubleArrowLeft /></i>
        </button>
        <div className='article-info flex-center'>
          <div className='article-infos-container'><i><FaRegUserCircle /></i><span>امیرحسین آقاجان زاده</span></div>
          <div className='article-infos-container'><span>1403-07-25</span><i><FaRegCalendarAlt /></i></div>
          <div className='article-infos-container'><span>15:25:54</span><i><FaRegClock /></i></div>
          <div className='article-infos-container'><span>78</span><i><AiOutlineLike /></i></div>
          <div className='article-infos-container'><span>48</span><i><AiOutlineDislike /></i></div>
          <div className='article-infos-container'><span>56</span><i><FaHeart /></i></div>
          <div className='article-infos-container'><span>182</span><i><RiShareForwardLine /></i></div>
          <div className='article-infos-container'><span>61</span><i><FaRegCommentDots /></i></div>
        </div>
      </section>
    </article>
  )
}
