import React from 'react';
import { useParams } from 'react-router-dom';
import productsData from '../Data/ProductsData';
import Footer from './Footer';
import { TiTick } from "react-icons/ti";
import Header from './Header';

export default function Product() {
  let params = useParams();
  let mainProductData = productsData.find(product => product.id == params.productID);
  return (
    <>
      <Header />
      <section className='products-details-container'>
        <div className='details-container'>
          <div className='image-price-container'>
            <img alt='' src={mainProductData.image} className='details-image' />
            <div className='details-price-container'>
              <span className='details-price-right-circle'></span>
              <span className='details-price-unit'>تومان</span>
              <span className='details-price-border'></span>
              <span className='details-price'>{mainProductData.price}</span>
              <span className='details-price-left-circle'></span>
            </div>
          </div>
          <div className='other-details-container'>
            <h2 style={{paddingRight: '20px', marginTop: '20px', fontSize: '28px'}}>نام محصول :  {mainProductData.name}</h2>
            <h4>توضیحات محصول : </h4>
            <p className='description'>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده
            </p>
            <h4>جزئیات محصول : </h4>
            <div className='features-list-container'>
              <li>
                <i><TiTick /></i>
                <span>گارانتی 12 ماهه تکنو استور</span>
              </li>
              <li>
                <i><TiTick /></i>
                <span>طراحی شیک</span>
              </li>
              <li>
                <i><TiTick /></i>
                <span>ظاهر زیبا و مدرن</span>
              </li>
              <li>
                <i><TiTick /></i>
                <span>وزن سبک و قابلیت حمل آسان</span>
              </li>
              <li>
                <i><TiTick /></i>
                <span>قیمت مناسب و ارزان</span>
              </li>
            </div>
            <div className='shopping-btn-container flex-center'>
              <button className='shopping-btn'>سفارش کالا و پرداخت</button>
            </div>
          </div>
          <div className='services-container flex-center'>
            <div className='services-item flex-center'>
              <img alt='' src='../images/express-delivery.png' />
              <span>امکان تحویل اکسپرس</span>
            </div>
            <div className='services-item flex-center'>
              <img alt='' src='../images/support.png' />
              <span>پشتیبانی 7 روزه هفته 24 ساعته</span>
            </div>
            <div className='services-item flex-center'>
              <img alt='' src='../images/cash-on-delivery.png' />
              <span>امکان پرداخت در محل</span>
            </div>
            <div className='services-item flex-center'>
              <img alt='' src='../images/days-return.png' />
              <span>7 روز ضمانت بلزگشت کالا</span>
            </div>
            <div className='services-item flex-center'>
              <img alt='' src='../images/original-products.png' />
              <span>ضمانت اصل بودن کالا</span>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
