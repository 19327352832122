import React, { useRef, useContext } from 'react';
import { NavLink } from 'react-bootstrap';
import { SiMicrosoftstore } from "react-icons/si";
import Footer from './Footer';
import { BsFire } from "react-icons/bs";
import { BsStars } from "react-icons/bs";
import { SlArrowRight } from "react-icons/sl";
import { SlArrowLeft } from "react-icons/sl";
import productsContext from '../Contexts/ProductsContext';
import Header from './Header';
import Timer from './Timer';

export default function Home() {
  const contextData = useContext(productsContext)
  const scrollRef = useRef(null);
  const handelScroll = () => {
    if (scrollRef.current) {
      const {scrollTop , scrollLeft} = scrollRef.current;
      contextData.setSliderScrollPosition({scrollTop , scrollLeft});
    };
  };
  const nextScrollHandler = () => {
    scrollRef.current.scrollLeft -= 100;
  };
  const prevScrollHandler = () => {
    scrollRef.current.scrollLeft += 100;
  };
  return (
    <div>
      <Header />
      <section className='banner-container'>
        <h1>تکنو استور</h1>
        <h4 className='slogan-1'>دیوانه وار با شما هستیم.</h4>
        <h4 className='slogan-2'>درست همینجا درست همین حالا وقتشه...</h4>
        <NavLink to="/store">
          <button>
            <i><SiMicrosoftstore /></i>
            بازدید از فروشگاه
          </button>
        </NavLink>
      </section>
      <section className='amazing-offer-container'>
        <section className='offer-image-container flex-center'>
          <img alt='' src='images/amazing-offer-image.png' className='offer-image' />
          <section className='timer-container flex-center'>
            <div className='counter-container flex-center'>
              <Timer date_and_time={5 * 24 * 60 * 60 * 1000} />
            </div>
            <div className='timer-labels-container flex-center'>
              <span>Seconds</span>
              <span>Minutes</span>
              <span>Hour</span>
              <span>Day</span>
            </div>
          </section>
          <button className='slider-horizontal-scroll-next-btn' onClick={nextScrollHandler}>
            <SlArrowLeft />
          </button>
          <button className='slider-horizontal-scroll-prev-btn' onClick={prevScrollHandler}>
            <SlArrowRight />
          </button>
        </section>
        <section className='amazing-offer-slider-container'>
          <div className='slider-items-container' ref={scrollRef} onScroll={handelScroll}>
            {
              contextData.allProducts.filter(filteredProducts => filteredProducts.amazingOffer === true).map(products =>(
                <div className='cart' key={products.id}>
                  <div className="cart-image-container flex-center">
                    <img alt='' src={products.image} />
                  </div>
                  <span className='discount-percent'>9%</span>
                  <span className='offer-product-name'>{products.name}</span>
                  <span className='offer-old-price'>تومان {products.price}</span>
                  <span className='offer-new-price'>تومان {products.price - (products.price * 0.09)}</span>
                </div>
              ))
            }
          </div>
        </section>
      </section>
      <section className='best-selling-products-container'>
        <section className='selling-products'>
          <div className='selling-title flex-center'>
            <BsFire className='fire-icon' />
            <h4 style={{display: 'inline-block'}}>پرفروش ترین محصولات</h4>
          </div>
          <div className='selling-body flex-center'>
            {
              contextData.allProducts.filter(filteredProducts => filteredProducts.bestSellingProducts === true).map(products =>(
                <div className='selling-cart flex-center' key={products.id}>
                  <span className='selling-cart-price'>تومان {products.price}</span>
                  <span className='selling-cart-name'>{products.name}</span>
                  <img alt='' src={products.image} />
                </div>
              ))
            }
          </div>
        </section>
      </section>
      <section className='most-popular-brands-container'>
        <section className='popular-container'>
          <div className='brands-title flex-center'>
            <BsStars className='star-icon' />
            <h4 style={{display: 'inline-block'}}>محبوب ترین برند ها</h4>
          </div>
          <div className='brands-container flex-center'>
            <img alt='' src='images/samsung.png' />
            <img alt='' src='images/apple.jpg' />
            <img alt='' src='images/huawei.png' />
            <img alt='' src='images/hp.png' />
            <img alt='' src='images/xiaomi.png' />
            <img alt='' src='images/asus.png' />
            <img alt='' src='images/lenovo.jpg' />
          </div>
        </section>
      </section>
      <Footer />
    </div>
  )
}
