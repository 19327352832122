import React, { useContext, useEffect, useRef } from 'react';
import Footer from './Footer';
import Products from './Products';
import productsContext from '../Contexts/ProductsContext';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import Header from './Header';

export default function Store() {
  const contextData = useContext(productsContext);

  let itemCount = 9;
  let pagesNumbers;
  useEffect(() => {
    let endIndex = itemCount * contextData.currentPage;
    let startIndex = endIndex - itemCount;
    let allShownProducts = contextData.allProducts.slice(startIndex, endIndex);
    contextData.setPaginated(allShownProducts);
  }, [contextData.currentPage]);
  const changePaginate = (newPage) => {
    contextData.setCurrentPage(newPage);
  };
  const pagesCount = Math.ceil(contextData.allProducts.length / itemCount);
  pagesNumbers = Array.from(Array(pagesCount).keys());

  const dropDownHandler = () => {
    contextData.setDropDown(!contextData.dropDown);
  };
  const selectBoxRef = useRef()
  const filterItems = (e) => {
    let liElemValue = e.target.innerText;
    selectBoxRef.current.innerText = ' ' + liElemValue;
    const updateItems = contextData.allProducts.filter((item) => {
      return item.category === liElemValue;
    });
    contextData.setPaginated(updateItems);
  };
  return (
    <div>
      <Header />
      <main className='content-container flex-center'>
        <section className='side-bar'>
          <div className='products-filter-select-box-container' onClick={dropDownHandler}>
            <div className='select-box-label-container'>
              <span ref={selectBoxRef}>انتخاب کنید...</span>
            </div>
            <div className='select-box-icon-container flex-center'>
              <i>
                {
                  contextData.dropDown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />
                }
              </i>
            </div>
            <ul className={`select-box-items-container ${contextData.dropDown ? 'show' : 'hide'}`}>
              <li className='item' onClick={(e) => filterItems(e)}>موبایل</li>
              <li className='item' onClick={(e) => filterItems(e)}>تبلت</li>
              <li className='item' onClick={(e) => filterItems(e)}>لپ تاپ</li>
              <li className='item' onClick={(e) => filterItems(e)}>کیس کامپیوتر</li>
              <li className='item' onClick={(e) => filterItems(e)}>مانیتور</li>
              <li className='item' onClick={(e) => filterItems(e)}>شارژر</li>
              <li className='item' onClick={(e) => filterItems(e)}>کول پد</li>
              <li className='item' onClick={(e) => filterItems(e)}>ماوس</li>
              <li className='item' onClick={(e) => filterItems(e)}>کیبورد</li>
              <li className='item' onClick={(e) => filterItems(e)}>ماوس پد</li>
              <li className='item' onClick={(e) => filterItems(e)}>هولدر موبایل</li>
              <li className='item' onClick={(e) => filterItems(e)}>هدفون</li>
              <li className='item' onClick={(e) => filterItems(e)}>ایرپاد</li>
              <li className='item' onClick={(e) => filterItems(e)}>اسپیکر کامپیوتر</li>
              <li className='item' onClick={(e) => filterItems(e)}>اسپیکر خانگی</li>
              <li className='item' onClick={(e) => filterItems(e)}>گلس گوشی</li>
              <li className='item' onClick={(e) => filterItems(e)}>قاب گوشی</li>
              <li className='item' onClick={(e) => filterItems(e)}>پاوربانک</li>
              <li className='item' onClick={(e) => filterItems(e)}>مودم</li>
              <li className='item' onClick={(e) => filterItems(e)}>پرینتر</li>
              <li className='item' onClick={(e) => filterItems(e)}>باتری</li>
              <li className='item' onClick={(e) => filterItems(e)}>هارد اکسترنال</li>
              <li className='item' onClick={(e) => filterItems(e)}>حافظه جانبی و USB</li>
            </ul>
          </div>
        </section>
        <section className='main-content'>
          <section className='products-container flex-center'>
            {
              contextData.paginated.map(product => (
                <Products key={product.id} {...product} />
              ))
            }
          </section>
          <section className='pagination-container flex-center'>
            <div className='pagination-container-menu'>
              {
                pagesNumbers.map((pageNumber) => (
                  <span key={pageNumber + 1} onClick={() => changePaginate(pageNumber + 1)} 
                  className={`${pageNumber + 1 === contextData.currentPage ? 'pagination-active' : ''}`}>{pageNumber + 1}</span>
                ))
              }
            </div>
          </section>
        </section>
      </main>
      <Footer />
    </div>
  )
}
