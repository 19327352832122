import React from 'react';

export default function Panel() {
  return (
    <div className='panel flex-center'>
      <h3>به پنل کاربری خود خوش آمدید.</h3>
      <img alt='' src='../images/welcome-gif.gif' />
    </div>
  )
}
