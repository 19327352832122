let posts = [
    {
        id : 1,
        name : "مهری احمدعلیزاده",
        date : "1403-07-16 | 20:56:41",
        avatar : "../images/avatar1.png",
        comment : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
    },
    {
        id : 2,
        name : "امیرحسین آقاجان زاده",
        date : "1403-07-16 | 20:56:41",
        avatar : "../images/avatar2.png",
        comment : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
    },
    {
        id : 3,
        name : "مهری احمدعلیزاده",
        date : "1403-07-16 | 20:56:41",
        avatar : "../images/avatar3.png",
        comment : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
    },
    {
        id : 4,
        name : "امیرحسین آقاجان زاده",
        date : "1403-07-16 | 20:56:41",
        avatar : "../images/avatar4.png",
        comment : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
    },
    {
        id : 5,
        name : "مهری احمدعلیزاده",
        date : "1403-07-16 | 20:56:41",
        avatar : "../images/avatar5.png",
        comment : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
    },
    {
        id : 6,
        name : "امیرحسین آقاجان زاده",
        date : "1403-07-16 | 20:56:41",
        avatar : "../images/avatar6.png",
        comment : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است"
    }
];

export default posts;
