import React, { useContext } from 'react';
import { IoClose } from "react-icons/io5";
import productsContext from '../Contexts/ProductsContext';

export default function Toast() {
  const contextData = useContext(productsContext);
  return (
    <section className={`toast-alert flex-center ${contextData.isShowToast ? 'toast-opacity' : 'hide'}`}>
      <p className='toast-message-text'>
        محصول با موفقیت به سبد اضافه شد.
        <img alt='' src='../images/emoji.png' className='toast-sticker' />
      </p>
      <button className='toast-close-btn' onClick={() => contextData.setIsShowToast(false)}>
        <IoClose />
      </button>
      <div className='toast-progress-bar'></div>
    </section>
  )
}
