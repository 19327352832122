import React, { useContext } from 'react';
import { FaTrashCan } from "react-icons/fa6";
import { AiOutlineStop } from "react-icons/ai";
import { TfiClose } from "react-icons/tfi";
import productsContext from '../Contexts/ProductsContext';

export default function Basket() {
    const contextData = useContext(productsContext);
    const removeProduct = (product) => {
        const deleteItem = contextData.userCart.filter((item) => item.id !== product.id);
        contextData.setUserCart(deleteItem);
        for (let i = 0; i < contextData.totalPrice.length; i++) {
            if (contextData.totalPrice[i] === product.price) {
                contextData.totalPrice.splice(i, 1);
            };
        };
    };
  return (
    <section className={`basket-container ${contextData.isShowBasket ? 'show' : 'hide'}`}>
        <header className='basket-header flex-center'>
            <h4>سبد خرید</h4>
            <button className='basket-close-btn' onClick={() => {
                contextData.setIsShowBasket(false);
            }}>
                <TfiClose />
            </button>
        </header>
        <section className='basket-body'>
            {
                contextData.userCart.map(product => (
                    <div className='basket-item flex-center' key={product.id}>
                        <button className='basket-item-delete-btn flex-center' onClick={() => removeProduct(product)}>
                            حذف
                            <FaTrashCan />
                        </button>
                        <span className='basket-item-count'>{product.count}</span>
                        <span className='basket-item-price'>تومان {product.price}</span>
                        <span className='basket-item-name'>{product.name}</span>
                        <img alt='' src={product.image} className='basket-item-image' />
                    </div>
                ))
            }
        </section>
        <section className='basket-footer flex-center'>
            <button className='basket-empty-btn flex-center' onClick={() => {
                contextData.setUserCart([]);
                contextData.setTotalPrice([0, 0]);
            }}>
                تخلیه سبد
                <AiOutlineStop />
            </button>
            <div className='basket-items-count-container'>
                <span>تعداد محصول : </span>
                <span>
                    {
                        contextData.userCart ? contextData.userCart.length : '0'
                    }
                </span>
            </div>
            <div className='basket-total-price-container'>
                <span>قیمت کل : </span>
                <span>
                    {
                        contextData.totalPrice.reduce((a , b) => a + b) + ' تومان'
                    }
                </span>
            </div>
        </section>
    </section>
  )
}
