import React, { useContext, useRef } from 'react';
import { FaUser } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { IoIosClose } from "react-icons/io";
import productsContext from '../Contexts/ProductsContext';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const passRef = useRef();
  const contextData = useContext(productsContext);
  const navigate = useNavigate();
  const togglePasswordInput = (e) => {
    e.preventDefault();
    if (passRef.current.attributes.type.value === 'password') {
      passRef.current.attributes.type.value = 'text';
      contextData.setToggleEye('text');
    } else {
      passRef.current.attributes.type.value = 'password';
      contextData.setToggleEye('password');
    };
  };
  const submitHandler = (e) => {
    e.preventDefault();
    navigate('/dashboard');
  };
  return (
    <section className='log-in-container flex-center'>
      <form action='#' className='account-form'>
        <div className='form-row-1 flex-center'>
          <label htmlFor='user-name-input'>نام کاربری : </label>
          <div className='div-elem'>
            <input type='text' className='account-form-input' id='user-name-input' />
            <i className='placeholder-icon'><FaUser /></i>
          </div>
          <i className='message-icon flex-center'><TiTick style={{display: 'none'}} /> <IoIosClose style={{display: 'none'}} /></i>
          <span className='form-message-label'>امیرحسین آقاجان زاده گودرزی 1378/01/01</span>
        </div>
        <div className='form-row-2 flex-center'>
          <label htmlFor='password-input'>گذرواژه : </label>
          <div className='div-elem'>
            <input type='password' className='account-form-input' id='password-input' ref={passRef} />
            <i className='placeholder-icon'><FaLock /></i>
            <button className='eye-btn' onClick={(e) => togglePasswordInput(e)}>
              <i>
                {
                  contextData.toggleEye === 'text' ? <IoEyeOff /> : <IoEye />
                }
              </i>
            </button>
          </div>
          <i className='message-icon flex-center'><TiTick style={{display: 'none'}} /> <IoIosClose style={{display: 'none'}} /></i>
          <span className='form-message-label'>امیرحسین آقاجان زاده گودرزی 1378/01/01</span>
        </div>
        <div className='form-row-4 flex-center'>
          <div style={{width: '100%'}}>
            <input type='checkbox' id='privacy-checkbox' />
            <label htmlFor='privacy-checkbox' className='privacy-label'>شرایط و قوانین را قبول دارم</label>
          </div>
          <i className='message-icon flex-center'><TiTick style={{display: 'none'}} /> <IoIosClose style={{display: 'none'}} /></i>
          <span className='form-message-label'>امیرحسین آقاجان زاده گودرزی 1378/01/01</span>
        </div>
        <div className='form-row-5 flex-center'>
          <button className='account-form-btn' onClick={(e) => submitHandler(e)}>ورود</button>
        </div>
      </form>
    </section>
  )
}
