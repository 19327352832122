import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NotFound() {
  return (
    <>
      <section className='not-found-container'>
        <h1>۴۰۴</h1>
        <h5>صفحه مورد نظر یافت نشد...!</h5>
        <div className='gif-container flex-center'>
            <img alt='' src='../images/not-found-gif.gif' />
        </div>
        <h5>
            به نظر میرسه گم شدی...!
            <img alt='' src='../images/emoji-2.png' />
        </h5>
        <div className='return-btn-container flex-center'>
            <button className='return-btn'>
                <NavLink to='/'>
                    برو به صفحه اصلی
                </NavLink>
            </button>
        </div>
      </section>
    </>
  )
}
